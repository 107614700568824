import queryString from 'query-string';
import api from './api';

const baseURL = '/users';

export default {
  name: 'userService',

  async create(data) {
    const body = {
      name: data.name,
      email: data.email,
      password: data.password,
      roles: data.roles,
      enterpriseId: data.enterpriseId,
    };

    return api.post(`${baseURL}/`, body).then((response) => response.data);
  },

  async delete(dataId) {
    return api.delete(`${baseURL}/${dataId}`).then((response) => response.data);
  },

  async update(data) {
    const body = {
      name: data.name,
      email: data.email,
      roles: data.roles,
      enterpriseId: data.enterpriseId,
    };

    return api
      .put(`${baseURL}/${data._id}`, body)
      .then((response) => response.data);
  },

  async updatePassword(data) {
    const body = {
      password: data.password,
      confirm_password: data.confirm_password,
    };

    return api
      .patch(`${baseURL}/changePassword/${data._id}`, body)
      .then((response) => response.data);
  },

  async updateUserInfos(data) {
    const body = {
      name: data.name,
      email: data.email,
      roles: data.roles,
      enterpriseId: data.enterpriseId,
    };
    return api
      .patch(`${baseURL}/${data._id}`, body)
      .then((response) => response.data);
  },

  async getAll(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${baseURL}/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 15, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${baseURL}/paginate/?${stringParams}`)
      .then((response) => response.data);
  },

  async getById(dataId) {
    return api.get(`${baseURL}/${dataId}`).then((response) => response.data);
  },

  async getByEmail(email) {
    return api
      .get(`${baseURL}/getByEmail/${email}`)
      .then((response) => response.data);
  },

  async getToComplete(token, id) {
    return api
      .get(`${baseURL}/to-complete/${token}/${id}`)
      .then((response) => response.data);
  },

  async completeUserPassword(data) {
    const body = {
      password: data.password,
    };
    return api
      .patch(`${baseURL}/complete-password/${data.token}/${data.id}`, body)
      .then((response) => response.data);
  },

  async sendRecoveryEmail(email) {
    return api
      .patch(`${baseURL}/send-recovery-email/${email}`)
      .then((response) => response.data);
  },

  async recoverUserPassword(data) {
    const body = {
      password: data.password,
    };
    return api
      .patch(`${baseURL}/recover-password/${data.token}/${data.id}`, body)
      .then((response) => response.data);
  },
};
