import { useToast } from 'vue-toastification';
import questionService from '../../services/questionService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    questions: undefined,
    selectedQuestion: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.questions = undefined;
      state.selectedQuestion = undefined;
    },
    CLEAR_QUESTIONS(state) {
      state.questions = undefined;
    },
    CLEAR_SELECTED_QUESTION(state) {
      state.selectedQuestion = undefined;
    },
    SET_QUESTIONS(state, questions) {
      state.questions = questions;
    },
    SET_SELECTED_QUESTION(state, question) {
      state.selectedQuestion = question;
    },
  },
  actions: {
    async getAllQuestions({ commit }, params) {
      await questionService
        .getAll(params)
        .then((response) => {
          commit('SET_QUESTIONS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getQuestionById({ commit }, id) {
      await questionService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_QUESTION', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateQuestions({ commit }, { page, params }) {
      await questionService
        .paginate(page, params)
        .then((response) => {
          commit('SET_QUESTIONS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createQuestion(state, data) {
      await questionService
        .create(data)
        .then(() => {
          toast.success('Questão cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateQuestion(state, { data, id }) {
      await questionService
        .update({ data, id })
        .then(() => {
          toast.success('Questão atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteQuestion(state, id) {
      await questionService
        .delete(id)
        .then(() => {
          toast.success('Questão deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
