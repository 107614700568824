import { useToast } from 'vue-toastification';
import logsService from '../../services/logsService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    logs: undefined,
    selectedLogs: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.logs = undefined;
      state.selectedLogs = undefined;
    },
    CLEAR_LOGS(state) {
      state.logs = undefined;
    },
    CLEAR_SELECTED_LOGS(state) {
      state.selectedLogs = undefined;
    },
    SET_LOGS(state, logs) {
      state.logs = logs;
    },
    SET_SELECTED_LOGS(state, logs) {
      state.selectedLogs = logs;
    },
  },
  actions: {
    async getAllLogs({ commit }, params) {
      await logsService
        .getAll(params)
        .then((response) => {
          commit('SET_LOGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getLogsById({ commit }, id) {
      await logsService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_LOGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateLogs({ commit }, { page, params, data }) {
      await logsService
        .paginate(page, params, data)
        .then((response) => {
          commit('SET_LOGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createLogs(state, data) {
      await logsService
        .create(data)
        .then(() => {
          toast.success('Leitura cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateLogs(state, { data, id }) {
      await logsService
        .update({ data, id })
        .then(() => {
          toast.success('Leitura atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteLogs(state, id) {
      await logsService
        .delete(id)
        .then(() => {
          toast.success('Leitura deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
