import { useToast } from 'vue-toastification';
import tagsService from '../../services/tagsService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    tags: undefined,
    selectedTag: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.tags = undefined;
      state.selectedTag = undefined;
    },
    CLEAR_TAGS(state) {
      state.tags = undefined;
    },
    CLEAR_SELECTED_TAG(state) {
      state.selectedTag = undefined;
    },
    SET_TAGS(state, tags) {
      state.tags = tags;
    },
    SET_SELECTED_TAG(state, tag) {
      state.selectedTag = tag;
    },
  },
  actions: {
    async getAllTags({ commit }, params) {
      await tagsService
        .getAll(params)
        .then((response) => {
          commit('SET_TAGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getTagById({ commit }, id) {
      await tagsService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_TAG', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateTags({ commit }, { page, params }) {
      await tagsService
        .paginate(page, params)
        .then((response) => {
          commit('SET_TAGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createTag(state, data) {
      await tagsService
        .create(data)
        .then(() => {
          toast.success('Tag cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateTag(state, { data, id }) {
      await tagsService
        .update({ data, id })
        .then(() => {
          toast.success('Tag atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteTag(state, id) {
      await tagsService
        .delete(id)
        .then(() => {
          toast.success('Tag deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async findAllWithoutVehicle({ commit }, params) {
      await tagsService
        .findAllWithoutVehicle(params)
        .then((response) => {
          commit('SET_TAGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async paginateWithoutVehicle({ commit }, { page, params }) {
      await tagsService
        .paginateWithoutVehicle(page, params)
        .then((response) => {
          commit('SET_TAGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
