import { useToast } from 'vue-toastification';
import vehicleModelService from '../../services/vehicleModelService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    vehicleModel: undefined,
    selectedVehicleModel: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.vehicleModel = undefined;
      state.selectedVehicleModel = undefined;
    },
    CLEAR_VEHICLE_MODEL(state) {
      state.vehicleModel = undefined;
    },
    CLEAR_SELECTED_VEHICLE_MODEL(state) {
      state.selectedVehicleModel = undefined;
    },
    SET_VEHICLE_MODEL(state, vehicleModel) {
      state.vehicleModel = vehicleModel;
    },
    SET_SELECTED_VEHICLE_MODEL(state, vehicleModel) {
      state.selectedVehicleModel = vehicleModel;
    },
  },
  actions: {
    async getAllVehicleModels({ commit }, params) {
      await vehicleModelService
        .getAll(params)
        .then((response) => {
          commit('SET_VEHICLE_MODEL', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedVehicleModels({ commit }, params) {
      await vehicleModelService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_VEHICLE_MODEL', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getVehicleModelById({ commit }, id) {
      await vehicleModelService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_VEHICLE_MODEL', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateVehicleModels({ commit }, { page, params }) {
      await vehicleModelService
        .paginate(page, params)
        .then((response) => {
          commit('SET_VEHICLE_MODEL', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createVehicleModel(state, data) {
      await vehicleModelService
        .create(data)
        .then(() => {
          toast.success('Modelo de veículo cadastrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateVehicleModel(state, { data, id }) {
      await vehicleModelService
        .update({ data, id })
        .then(() => {
          toast.success('Modelo de veículo atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteVehicleModel(state, id) {
      await vehicleModelService
        .delete(id)
        .then(() => {
          toast.success('Modelo de veículo deletado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
