import { useToast } from 'vue-toastification';
import visitReportsService from '../../services/visitReportsService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    visitsKpi: undefined,
    visitsReport: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.visitsKpi = undefined;
      state.visitsReport = undefined;
    },
    CLEAR_VISITS_KPI(state) {
      state.visitsKpi = undefined;
    },
    SET_VISITS_KPI(state, visits) {
      state.visitsKpi = visits;
    },
    SET_VISITS_REPORT(state, report) {
      state.visitsReport = report;
    },
  },
  actions: {
    async getVisitsReport({ commit }, params) {
      await visitReportsService
        .getVisitsReport(params)
        .then((response) => {
          commit('SET_VISITS_REPORT', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },

    async getVisitsKpi({ commit }, params) {
      await visitReportsService
        .getVisitsKpi(params)
        .then((response) => {
          commit('SET_VISITS_KPI', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },

    async getVisitsDashboard({ commit }, params) {
      await visitReportsService
        .getVisitsDashboard(params)
        .then((response) => {
          commit('SET_VISITS_KPI', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
