<template>
  <div class="d-flex justify-content-center flex-row">
    <CDropdown variant="nav-item" auto-close="outside">
      <CDropdownToggle
        placement="bottom-end"
        class="p-2 border rounded-circle d-flex items-center justify-content-center"
        :caret="false"
      >
        <CIcon style="cursor: pointer" icon="cil-settings" size="lg" />
      </CDropdownToggle>
      <CDropdownMenu>
        <div class="text-center py-2 px-2">
          <label class="pb-2" for="enterpriseSelect"
            >Filtrar por empresa:</label
          >
          <select
            class="select-style"
            name="enterpriseSelect"
            id="enterpriseSelect"
            placeholder="Selecione uma empresa"
            v-model="inputValue"
            @change="handleChange"
          >
            <option
              v-for="option in options"
              :value="option.value"
              :key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
      </CDropdownMenu>
    </CDropdown>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

export default {
  name: 'select-enterprise',
  computed: {
    ...mapState('loginStore', ['myUser']),
    ...mapState('enterpriseStore', ['enterprisesToFilter']),
  },
  data() {
    return {
      options: [],
      inputValue: cookies.get('currentEnterprise'),
    };
  },
  methods: {
    ...mapActions({
      getAllEnterprises: 'enterpriseStore/getEnterprisesToFilter',
    }),
    ...mapMutations({
      CLEAR_ENTERPRISES_TO_FILTER:
        'enterpriseStore/CLEAR_ENTERPRISES_TO_FILTER',
    }),

    async getEnterprises() {
      await this.getAllEnterprises();
      this.options = [];
      for (let index = 0; index < this.enterprisesToFilter.length; index++) {
        const element = this.enterprisesToFilter[index];
        this.options.push({
          text: element.name,
          value: element.id,
        });
      }
    },

    handleChange() {
      cookies.set('currentEnterprise', this.inputValue);
      window.location.reload();
    },
  },
  created() {
    this.getEnterprises();
  },
};
</script>

<style scoped>
.select-style {
  border: 1px solid rgba(44, 56, 74, 0.681);
  width: 100%;
  border-radius: 4px;
}

select:focus-visible {
  border-color: #475468;
}
</style>
