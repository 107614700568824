import { useToast } from 'vue-toastification';
import visitService from '../../services/visitService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    visits: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.visits = undefined;
    },
    CLEAR_VISITS(state) {
      state.visits = undefined;
    },
    SET_VISITS(state, visits) {
      state.visits = visits;
    },
  },
  actions: {
    async getAllVisits({ commit }, params) {
      await visitService
        .getAll(params)
        .then((response) => {
          commit('SET_VISITS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateVisits({ commit }, { page, pageLimit, params }) {
      await visitService
        .paginate(page, pageLimit, params)
        .then((response) => {
          commit('SET_VISITS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
