import { useToast } from 'vue-toastification';
import statusService from '../../services/statusService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    status: undefined,
    selectedStatus: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.status = undefined;
      state.selectedStatus = undefined;
    },
    CLEAR_STATUS(state) {
      state.status = undefined;
    },
    CLEAR_SELECTED_STATUS(state) {
      state.selectedStatus = undefined;
    },
    SET_STATUS(state, status) {
      state.status = status;
    },
    SET_SELECTED_STATUS(state, status) {
      state.selectedStatus = status;
    },
  },
  actions: {
    async getAllStatus({ commit }, params) {
      await statusService
        .getAll(params)
        .then((response) => {
          commit('SET_STATUS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getStatusById({ commit }, id) {
      await statusService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_STATUS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getStatusHistory({ commit }, { page, params, data }) {
      await statusService
        .getStatusHistory(page, params, data)
        .then((response) => {
          commit('SET_STATUS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateAntennasStatus({ commit }, { page, params }) {
      await statusService
        .paginateAntennas(page, params)
        .then((response) => {
          commit('SET_STATUS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateStatus({ commit }, { page, params, data }) {
      await statusService
        .paginate(page, params, data)
        .then((response) => {
          commit('SET_STATUS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createStatus(state, data) {
      await statusService
        .create(data)
        .then(() => {
          toast.success('Leitura cadastrada');
        })
        .catch((error) => {
          toast.error(error.response.data.message[0].message);
        });
    },
    async updateStatus(state, { data, id }) {
      await statusService
        .update({ data, id })
        .then(() => {
          toast.success('Leitura atualizada');
        })
        .catch((error) => {
          toast.error(error.response.data.message[0].message);
        });
    },
    async deleteStatus(state, id) {
      await statusService
        .delete(id)
        .then(() => {
          toast.success('Leitura deletada');
        })
        .catch((error) => {
          toast.error(error.response.data.message[0].message);
        });
    },
  },
  modules: {},
  getters: {},
};
