import { useToast } from 'vue-toastification';
import enterpriseService from '../../services/enterpriseService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    enterprises: undefined,
    enterprisesToFilter: undefined,
    selectedEnterprise: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.enterprises = undefined;
      state.selectedEnterprise = undefined;
    },
    CLEAR_ENTERPRISES(state) {
      state.enterprises = undefined;
    },
    CLEAR_SELECTED_ENTERPRISE(state) {
      state.selectedEnterprise = undefined;
    },
    CLEAR_ENTERPRISES_TO_FILTER(state) {
      state.enterprisesToFilter = undefined;
    },
    SET_ENTERPRISES(state, enterprises) {
      state.enterprises = enterprises;
    },
    SET_SELECTED_ENTERPRISE(state, enterprise) {
      state.selectedEnterprise = enterprise;
    },
    SET_ENTERPRISES_TO_FILTER(state, enterprises) {
      state.enterprisesToFilter = enterprises;
    },
  },
  actions: {
    async getAllEnterprises({ commit }, params) {
      await enterpriseService
        .getAll(params)
        .then((response) => {
          commit('SET_ENTERPRISES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getEnterprisesToFilter({ commit }, params) {
      await enterpriseService
        .getAll(params)
        .then((response) => {
          commit('SET_ENTERPRISES_TO_FILTER', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getEnterpriseById({ commit }, id) {
      await enterpriseService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_ENTERPRISE', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateEnterprises({ commit }, { page, params }) {
      await enterpriseService
        .paginate(page, params)
        .then((response) => {
          commit('SET_ENTERPRISES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedEnterprises({ commit }, params) {
      await enterpriseService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_ENTERPRISES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createEnterprise(state, data) {
      await enterpriseService
        .create(data)
        .then(() => {
          toast.success('Empresa cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateEnterprise(state, { data, id }) {
      await enterpriseService
        .update({ data, id })
        .then(() => {
          toast.success('Empresa atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteEnterprise(state, id) {
      await enterpriseService
        .delete(id)
        .then(() => {
          toast.success('Empresa deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
