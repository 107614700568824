import { useToast } from 'vue-toastification';
import productService from '../../services/productService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    products: undefined,
    selectedProduct: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.products = undefined;
      state.selectedProduct = undefined;
    },
    CLEAR_PRODUCTS(state) {
      state.products = undefined;
    },
    CLEAR_SELECTED_PRODUCT(state) {
      state.selectedProduct = undefined;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_SELECTED_PRODUCT(state, product) {
      state.selectedProduct = product;
    },
  },
  actions: {
    async getAllProducts({ commit }, params) {
      await productService
        .getAll(params)
        .then((response) => {
          commit('SET_PRODUCTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedProducts({ commit }, params) {
      await productService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_PRODUCTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getProductById({ commit }, id) {
      await productService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_PRODUCT', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateProducts({ commit }, { page, params }) {
      await productService
        .paginate(page, params)
        .then((response) => {
          commit('SET_PRODUCTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createProduct(state, data) {
      await productService
        .create(data)
        .then(() => {
          toast.success('Produto cadastrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateProduct(state, { data, id }) {
      await productService
        .update({ data, id })
        .then(() => {
          toast.success('Produto atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteProduct(state, id) {
      await productService
        .delete(id)
        .then(() => {
          toast.success('Produto deletado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
