import { useToast } from 'vue-toastification';
import shipmentService from '../../services/shipmentService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    shipments: undefined,
    selectedShipment: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.shipments = undefined;
      state.selectedShipment = undefined;
    },
    CLEAR_SHIPMENTS(state) {
      state.shipments = undefined;
    },
    CLEAR_SELECTED_SHIPMENT(state) {
      state.selectedShipment = undefined;
    },
    SET_SHIPMENTS(state, shipments) {
      state.shipments = shipments;
    },
    SET_SELECTED_SHIPMENT(state, shipment) {
      state.selectedShipment = shipment;
    },
  },
  actions: {
    async getAllShipments({ commit }, params) {
      await shipmentService
        .getAll(params)
        .then((response) => {
          commit('SET_SHIPMENTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getShipmentById({ commit }, id) {
      await shipmentService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_SHIPMENT', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateShipments({ commit }, { page, params }) {
      await shipmentService
        .paginate(page, params)
        .then((response) => {
          commit('SET_SHIPMENTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createShipment(state, data) {
      await shipmentService
        .create(data)
        .then(() => {
          toast.success('Carregamento cadastrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateShipment(state, { data, id }) {
      await shipmentService
        .update({ data, id })
        .then(() => {
          toast.success('Carregamento atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteShipment(state, id) {
      await shipmentService
        .delete(id)
        .then(() => {
          toast.success('Carregamento deletado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async closeShipment(state, { data, id }) {
      await shipmentService
        .closeShipment({ data, id })
        .then(() => {
          toast.success('Carregamento encerrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async sendQuestionnaireResponses(
      state,
      { data, shipmentId, questionnaireId },
    ) {
      await shipmentService
        .sendQuestionnaireResponses({ data, shipmentId, questionnaireId })
        .then(() => {
          toast.success('Respostas registradas');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async closeQuestionnaire(state, { data, shipmentId, questionnaireId }) {
      await shipmentService
        .closeQuestionnaire({ data, shipmentId, questionnaireId })
        .then(() => {
          toast.success('Questionário encerrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async shipmentsOfDay({ commit }, params) {
      await shipmentService
        .shipmentsOfDay(params)
        .then((response) => {
          commit('SET_SHIPMENTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async shipmentsOfDayPaginate({ commit }, { page, params }) {
      await shipmentService
        .shipmentsOfDayPaginate(page, params)
        .then((response) => {
          commit('SET_SHIPMENTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
