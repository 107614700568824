import { useToast } from 'vue-toastification';
import measureService from '../../services/measureService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    measures: undefined,
    selectedMeasure: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.measures = undefined;
      state.selectedMeasure = undefined;
    },
    CLEAR_MEASURES(state) {
      state.measures = undefined;
    },
    CLEAR_SELECTED_MEASURE(state) {
      state.selectedMeasure = undefined;
    },
    SET_MEASURES(state, measures) {
      state.measures = measures;
    },
    SET_SELECTED_MEASURE(state, measure) {
      state.selectedMeasure = measure;
    },
  },
  actions: {
    async getAllMeasures({ commit }, params) {
      await measureService
        .getAll(params)
        .then((response) => {
          commit('SET_MEASURES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedMeasures({ commit }, params) {
      await measureService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_MEASURES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getMeasureById({ commit }, id) {
      await measureService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_MEASURE', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateMeasures({ commit }, { page, params }) {
      await measureService
        .paginate(page, params)
        .then((response) => {
          commit('SET_MEASURES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createMeasure(state, data) {
      await measureService
        .create(data)
        .then(() => {
          toast.success('Unidade de medida cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateMeasure(state, { data, id }) {
      await measureService
        .update({ data, id })
        .then(() => {
          toast.success('Unidade de medida atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteMeasure(state, id) {
      await measureService
        .delete(id)
        .then(() => {
          toast.success('Unidade de medida deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
