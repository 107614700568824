<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu>
      <CDropdownHeader component="h6" class="text-center fw-bold py-2 mobile">
        {{ myUser.name }}
      </CDropdownHeader>
      <CDropdownItem @click="logout()">
        <CIcon icon="cil-lock-locked" /> Sair
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
// Vuex:
import { mapState, mapActions } from 'vuex';

import avatar from '@/assets/images/avatars/1.jpg';

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    };
  },
  computed: {
    ...mapState('loginStore', ['myUser']),
  },
  methods: {
    ...mapActions({ storeLogout: 'loginStore/logout' }),
    async logout() {
      await this.storeLogout();
    },
  },
};
</script>
<style scoped>
.mobile {
  display: none;
}

@media (max-width: 441px) {
  .mobile {
    display: block;
  }
}
</style>
