import { useToast } from 'vue-toastification';
import permissionService from '../../services/permissionService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    permissions: undefined,
    selectedPermission: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.permissions = undefined;
      state.selectedPermission = undefined;
    },
    CLEAR_PERMISSIONS(state) {
      state.permissions = undefined;
    },
    CLEAR_SELECTED_PERMISSION(state) {
      state.selectedPermission = undefined;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_SELECTED_PERMISSION(state, permission) {
      state.selectedPermission = permission;
    },
  },
  actions: {
    async getAllPermissions({ commit }, params) {
      await permissionService
        .getAll(params)
        .then((response) => {
          commit('SET_PERMISSIONS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedPermissions({ commit }, params) {
      await permissionService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.resource.localeCompare(b.resource));
          commit('SET_PERMISSIONS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPermissionById({ commit }, id) {
      await permissionService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_PERMISSION', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginatePermissions({ commit }, { page, params }) {
      await permissionService
        .paginate(page, params)
        .then((response) => {
          commit('SET_PERMISSIONS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createPermission(state, data) {
      await permissionService
        .create(data)
        .then(() => {
          toast.success('Permissão cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updatePermission(state, { data, id }) {
      await permissionService
        .update({ data, id })
        .then(() => {
          toast.success('Permissão atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deletePermission(state, id) {
      await permissionService
        .delete(id)
        .then(() => {
          toast.success('Permissão deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
