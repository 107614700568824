import { useToast } from 'vue-toastification';
import checkpointService from '../../services/checkpointService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    checkpoints: undefined,
    selectedCheckpoint: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.checkpoints = undefined;
      state.selectedCheckpoint = undefined;
    },
    CLEAR_CHECKPOINTS(state) {
      state.checkpoints = undefined;
    },
    CLEAR_SELECTED_CHECKPOINT(state) {
      state.selectedCheckpoint = undefined;
    },
    SET_CHECKPOINTS(state, checkpoints) {
      state.checkpoints = checkpoints;
    },
    SET_SELECTED_CHECKPOINT(state, checkpoint) {
      state.selectedCheckpoint = checkpoint;
    },
  },
  actions: {
    async getAllCheckpoints({ commit }, params) {
      await checkpointService
        .getAll(params)
        .then((response) => {
          commit('SET_CHECKPOINTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getCheckpointById({ commit }, id) {
      await checkpointService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_CHECKPOINT', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateCheckpoints({ commit }, { page, params }) {
      await checkpointService
        .paginate(page, params)
        .then((response) => {
          commit('SET_CHECKPOINTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createCheckpoint(state, data) {
      await checkpointService
        .create(data)
        .then(() => {
          toast.success('Estação cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateCheckpoint(state, { data, id }) {
      await checkpointService
        .update({ data, id })
        .then(() => {
          toast.success('Estação atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteCheckpoint(state, id) {
      await checkpointService
        .delete(id)
        .then(() => {
          toast.success('Estação deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
