import { useToast } from 'vue-toastification';
import userService from '../../services/userService';
import router from '../../router';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    users: undefined,
    selectedUser: undefined,
    userToCreate: {},
    userAlreadyExists: false,
    emailSent: false,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.users = undefined;
      state.selectedUser = undefined;
      state.userToCreate = {};
      state.userAlreadyExists = false;
      state.emailSent = false;
    },
    CLEAR_USERS(state) {
      state.users = undefined;
    },
    CLEAR_SELECTED_USER(state) {
      state.selectedUser = undefined;
    },
    CLEAR_USER_TO_CREATE(state) {
      state.userToCreate = {};
    },
    CLEAR_USER_ALREADY_EXISTS(state) {
      state.userAlreadyExists = false;
    },
    SET_USER_TO_CREATE(state, user) {
      state.userToCreate = user;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_SELECTED_USER(state, user) {
      state.selectedUser = user;
    },
    SET_USER_ALREADY_EXISTS(state, exists) {
      state.userAlreadyExists = exists;
    },
    SET_EMAIL_SENT(state, isSent) {
      state.emailSent = isSent;
    },
  },
  actions: {
    async getAllUsers({ commit }, params) {
      await userService
        .getAll(params)
        .then((response) => {
          commit('SET_USERS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getUserById({ commit }, id) {
      await userService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_USER', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },

    async getPaginateUsers({ commit }, { page, params }) {
      await userService
        .paginate(page, params)
        .then((response) => {
          commit('SET_USERS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createUser(state, data) {
      await userService
        .create(data)
        .then(() => {
          toast.success('Usuário cadastrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateUser(state, data) {
      await userService
        .update(data)
        .then(() => {
          toast.success('Usuário atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateUserInfos(state, data) {
      await userService
        .updateUserInfos(data)
        .then(() => {
          toast.success('Usuário atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateUserPassword(state, data) {
      await userService
        .updatePassword(data)
        .then(() => {
          toast.success('Senha atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },

    async deleteUser(state, id) {
      await userService
        .delete(id)
        .then(() => {
          toast.success('Usuário deletado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },

    async findUserToComplete({ commit }, data) {
      await userService
        .getToComplete(data.token, data.id)
        .then((response) => {
          commit('SET_SELECTED_USER', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);

          router.push('/login');
        });
    },

    async completeUserPassword(state, data) {
      await userService
        .completeUserPassword(data)
        .then(() => {
          toast.success('Nova senha cadastrada com sucesso!');
          router.push('/login');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },

    async sendRecoveryEmail({ commit }, email) {
      await userService.sendRecoveryEmail(email).then(() => {
        commit('SET_EMAIL_SENT', true);
        toast.success('Email enviado com sucesso');
      });
    },

    async recoverUserPassword(state, data) {
      await userService
        .recoverUserPassword(data)
        .then(() => {
          toast.success('Nova senha cadastrada com sucesso!');
          router.push('/login');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
