/* eslint-disable import/no-duplicates */
/* eslint-disable prefer-object-spread */
import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibDigg,
  cibCoreuiC,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
  cilSearch,
  cilHome,
  cilClock,
  cilBolt,
  cilCheckAlt,
  cilWarning,
  cilStar,
} from '@coreui/icons';

import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons';

import {
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilBook,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilTask,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilShareBoxed,
  cilCarAlt,
  cilPlus,
  cilX,
  cilPrint,
  cilInput,
  cilColorBorder,
  cilHistory,
  cilDelete,
  cilArrowCircleTop,
  cilArrowCircleBottom,
  cilAlignLeft,
  cilArrowLeft,
  cilTruck,
  cilAvTimer,
  cilArrowThickTop,
  cilArrowThickBottom,
} from '@coreui/icons';

export const iconsSet = Object.assign(
  {},
  {
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBook,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilTrash,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilShareBoxed,
    cilCarAlt,
    cilPlus,
    cilX,
    cilPrint,
    cilInput,
    cilHistory,
    cilColorBorder,
    cilDelete,
    cilArrowCircleTop,
    cilArrowCircleBottom,
    cilAlignLeft,
    cilSearch,
    cilArrowLeft,
    cilTruck,
    cilAvTimer,
    cilHome,
    cilClock,
    cilBolt,
    cilCheckAlt,
    cilWarning,
    cilArrowThickTop,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibDigg,
    cibCoreuiC,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cilArrowThickBottom,
  },
);
