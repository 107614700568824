<template>
  <div v-if="myUser">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import { CContainer } from '@coreui/vue';
import AppHeader from '@/components/layout/header/AppHeader.vue';
import AppSidebar from '@/components/layout/sidebar/AppSidebar.vue';

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
    CContainer,
  },

  computed: mapState('loginStore', ['myUser']),
  methods: {
    ...mapActions({ storeGetMe: 'loginStore/getMe' }),
    async getMe() {
      await this.storeGetMe();
    },
  },
  created() {
    this.getMe();
  },
};
</script>
