import queryString from 'query-string';
import { useCookies } from 'vue3-cookies';
import api from './api';

const { cookies } = useCookies();

const baseURL = '/status';

export default {
  name: 'statusService',

  getBaseUrl() {
    return `${baseURL}/${cookies.get('currentEnterprise')}`;
  },

  async getAll(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 12, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/paginate/?${stringParams}`)
      .then((response) => response.data);
  },
  async paginateAntennas(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 12, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/paginateAntennas/?${stringParams}`)
      .then((response) => response.data);
  },
  // eslint-disable-next-line default-param-last
  async getStatusHistory(page = 1, params = {}, data) {
    const stringParams = queryString.stringify(
      { page, limit: 12, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    const body = {
      id: data.id,
    };
    return api
      .post(`${this.getBaseUrl()}/byAntennas/?${stringParams}`, body)
      .then((response) => response.data);
  },

  async getById(dataId) {
    return api
      .get(`${this.getBaseUrl()}/${dataId}`)
      .then((response) => response.data);
  },
};
