import { useToast } from 'vue-toastification';
import questionnaireService from '../../services/questionnaireService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    questionnaires: undefined,
    selectedQuestionnaire: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.questionnaires = undefined;
      state.selectedQuestionnaire = undefined;
    },
    CLEAR_QUESTIONNAIRES(state) {
      state.Questionnaires = undefined;
    },
    CLEAR_SELECTED_QUESTIONNAIRE(state) {
      state.selectedQuestionnaire = undefined;
    },
    SET_QUESTIONNAIRES(state, Questionnaires) {
      state.questionnaires = Questionnaires;
    },
    SET_SELECTED_QUESTIONNAIRE(state, Questionnaire) {
      state.selectedQuestionnaire = Questionnaire;
    },
  },
  actions: {
    async getAllQuestionnaires({ commit }, params) {
      await questionnaireService
        .getAll(params)
        .then((response) => {
          commit('SET_QUESTIONNAIRES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedQuestionnaires({ commit }, params) {
      await questionnaireService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_QUESTIONNAIRES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getQuestionnaireById({ commit }, id) {
      await questionnaireService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_QUESTIONNAIRE', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateQuestionnaires({ commit }, { page, params }) {
      await questionnaireService
        .paginate(page, params)
        .then((response) => {
          commit('SET_QUESTIONNAIRES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createQuestionnaire(state, data) {
      await questionnaireService
        .create(data)
        .then(() => {
          toast.success('Questionário cadastrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateQuestionnaire(state, { data, id }) {
      await questionnaireService
        .update({ data, id })
        .then(() => {
          toast.success('Questionário atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteQuestionnaire(state, id) {
      await questionnaireService
        .delete(id)
        .then(() => {
          toast.success('Questionário deletado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
