import queryString from 'query-string';
import { useCookies } from 'vue3-cookies';
import api from './api';

const { cookies } = useCookies();

const baseURL = '/logs';

export default {
  name: 'logsService',

  getBaseUrl() {
    return `${baseURL}/${cookies.get('currentEnterprise')}`;
  },

  async getAll(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 12, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/paginate/?${stringParams}`)
      .then((response) => response.data);
  },

  async getById(dataId) {
    return api
      .get(`${this.getBaseUrl()}/${dataId}`)
      .then((response) => response.data);
  },
};
