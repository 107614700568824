import { useToast } from 'vue-toastification';
import reportService from '../../services/reportService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    reports: undefined,
    selectedReport: undefined,
    shipmentReports: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.reports = undefined;
      state.selectedReport = undefined;
      state.shipmentReports = undefined;
    },
    CLEAR_REPORTS(state) {
      state.reports = undefined;
    },
    CLEAR_SELECTED_REPORT(state) {
      state.selectedReport = undefined;
    },
    CLEAR_SPREADSHEET(state) {
      state.spreadsheet = undefined;
    },
    CLEAR_SHIPMENT_REPORTS(state) {
      state.shipmentReports = undefined;
    },
    SET_SHIPMENT_REPORTS(state, reports) {
      state.shipmentReports = reports;
    },
    SET_REPORTS(state, reports) {
      state.reports = reports;
    },
    SET_SELECTED_REPORT(state, report) {
      state.selectedReport = report;
    },
    SET_SPREADSHEET(state, spreadsheet) {
      state.spreadsheet = spreadsheet;
    },
  },
  actions: {
    async getShipmentReports({ commit }, params) {
      await reportService
        .getShipmentReports(params)
        .then((response) => {
          commit('SET_SHIPMENT_REPORTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },

    async getDashboardInfo({ commit }, params) {
      await reportService
        .getDashboard(params)
        .then((response) => {
          commit('SET_REPORTS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getShipmentsSpreadsheet({ commit }, params) {
      await reportService
        .getShipmentsSpreadsheet(params)
        .then((response) => {
          commit('SET_SPREADSHEET', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
