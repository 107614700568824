import queryString from 'query-string';
import { useCookies } from 'vue3-cookies';
import api from './api';

const { cookies } = useCookies();

const baseURL = '/report';

export default {
  name: 'reportService',

  getBaseUrl() {
    return `${baseURL}/${cookies.get('currentEnterprise')}`;
  },

  async getShipmentReports(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/shipments/?${stringParams}`)
      .then((response) => response.data);
  },

  async getDashboard(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/dashboard/?${stringParams}`)
      .then((response) => response.data);
  },

  async getShipmentsSpreadsheet(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/shipments/spreadsheet/?${stringParams}`)
      .then((response) => response.data);
  },
};
