import { useCookies } from 'vue3-cookies';
import { defineComponent, h, onMounted, ref, resolveComponent } from 'vue';
import { RouterLink, useRoute } from 'vue-router';

import { CSidebarNav, CNavItem, CNavGroup, CNavTitle } from '@coreui/vue';
import nav from '@/navItems/_nav.js';
import { hasPermission } from '../../../utils/authUtils';

const { cookies } = useCookies();

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '');

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false;
  }

  if (route.hash === link) {
    return true;
  }

  const currentPath = normalizePath(route.path);
  const targetPath = normalizePath(link);

  return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true;
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child));
  }

  return false;
};

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute();
    const firstRender = ref(true);

    const routeNeedEnterprise = (needed) => {
      if (needed && cookies.get('currentEnterprise') !== 'null') {
        return true;
      }
      if (!needed) {
        return true;
      }
      return false;
    };

    onMounted(() => {
      firstRender.value = false;
    });

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              h(resolveComponent('CIcon'), {
                customClassName: 'nav-icon',
                name: item.icon,
              }),
              item.name,
            ],
            default: () =>
              item.items
                .filter(
                  (child) =>
                    hasPermission(child.action, child.resource, child.roles) &&
                    routeNeedEnterprise(child.needEnterprise),
                )
                .map((child) => renderItem(child)),
          },
        );
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    href: props.href,
                    onClick: () => props.navigate(),
                  },
                  {
                    default: () => [
                      item.icon &&
                        h(resolveComponent('CIcon'), {
                          customClassName: 'nav-icon',
                          name: item.icon,
                        }),
                      item.name,
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            },
          );
    };

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () =>
            nav
              .filter(
                (item) =>
                  hasPermission(item.action, item.resource, item.roles) &&
                  routeNeedEnterprise(item.needEnterprise),
              )
              .map((item) => renderItem(item)),
        },
      );
  },
});
export { AppSidebarNav };
