import { useToast } from 'vue-toastification';
import configService from '../../services/configService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    configs: undefined,
    selectedConfig: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.configs = undefined;
      state.selectedConfig = undefined;
    },
    CLEAR_CONFIGS(state) {
      state.configs = undefined;
    },
    CLEAR_SELECTED_CONFIG(state) {
      state.selectedConfig = undefined;
    },
    SET_CONFIGS(state, configs) {
      state.configs = configs;
    },
    SET_SELECTED_CONFIG(state, config) {
      state.selectedConfig = config;
    },
  },
  actions: {
    async getAllConfigs({ commit }, params) {
      await configService
        .getAll(params)
        .then((response) => {
          commit('SET_CONFIGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getConfigById({ commit }, id) {
      await configService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_CONFIG', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateConfigs({ commit }, { page, params }) {
      await configService
        .paginate(page, params)
        .then((response) => {
          commit('SET_CONFIGS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createConfig(state, data) {
      await configService
        .create(data)
        .then(() => {
          toast.success('Configuração cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateConfig(state, { data, id }) {
      await configService
        .update({ data, id })
        .then(() => {
          toast.success('Configuração atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteConfig(state, id) {
      await configService
        .delete(id)
        .then(() => {
          toast.success('Configuração deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
