import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Modules:
import loginStore from './modules/loginStore';
import userStore from './modules/userStore';
import vehicleStore from './modules/vehicleStore';
import companyStore from './modules/companyStore';
import readerStore from './modules/readerStore';
import statusStore from './modules/statusStore';
import routesStore from './modules/routesStore';
import shipmentStore from './modules/shipmentStore';
import questionStore from './modules/questionStore';
import configStore from './modules/configStore';
import antennaStore from './modules/antennaStore';
import productStore from './modules/productStore';
import measureStore from './modules/measureStore';
import checkpointStore from './modules/checkpointStore';
import tagsStore from './modules/tagsStore';
import questionnaireStore from './modules/questionnaireStore';
import logsStore from './modules/logsStore';
import reportStore from './modules/reportStore';
import roleStore from './modules/roleStore';
import permissionStore from './modules/permissionStore';
import vehicleModelStore from './modules/vehicleModelStore';
import enterpriseStore from './modules/enterpriseStore';
import visitStore from './modules/visitStore';
import visitReportsStore from './modules/visitReportsStore';

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
  },
  actions: {},
  modules: {
    loginStore,
    userStore,
    vehicleStore,
    companyStore,
    readerStore,
    routesStore,
    shipmentStore,
    questionStore,
    configStore,
    antennaStore,
    productStore,
    measureStore,
    checkpointStore,
    tagsStore,
    statusStore,
    questionnaireStore,
    logsStore,
    reportStore,
    roleStore,
    permissionStore,
    vehicleModelStore,
    enterpriseStore,
    visitStore,
    visitReportsStore,
  },
  plugins: [createPersistedState()],
});
