// Service:
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCookies } from 'vue3-cookies';
import { useToast } from 'vue-toastification';
import authService from '../../services/auth';

const toast = useToast();
const { cookies } = useCookies();

export default {
  namespaced: true,
  state: {
    currentUser: {},
    myUser: undefined,
    currentEnterprise: undefined,
  },
  mutations: {
    SET_LOGGED_USER(state, user) {
      state.currentUser = user;
    },
    SET_MY_USER(state, user) {
      state.myUser = user;
    },
    CLEAR_LOGGED_USER(state) {
      state.currentUser = {};
    },
    CLEAR_MY_USER(state) {
      state.myUser = undefined;
    },
    CLEAR_STORE(state) {
      state.currentUser = {};
      state.myUser = undefined;
    },
  },
  actions: {
    async login({ commit }, data) {
      await authService
        .login(data)
        .then((response) => {
          if (
            response &&
            response.token &&
            response.user &&
            response.user.email
          ) {
            commit('SET_LOGGED_USER', response);
            cookies.set('currentEnterprise', response.user.enterpriseId);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getMe({ commit }) {
      await authService.getMe().then((response) => {
        commit('SET_MY_USER', response);
      });
    },
    logout() {
      cookies.remove('currentEnterprise');
      authService.logout();
    },
  },
  modules: {},
  getters: {
    currentUser: (state) => state.currentUser,
    myUser: (state) => state.myUser,
  },
};
