import queryString from 'query-string';
import { useCookies } from 'vue3-cookies';
import api from './api';

const { cookies } = useCookies();

const baseURL = '/questions';

export default {
  name: 'questionService',

  getBaseUrl() {
    return `${baseURL}/${cookies.get('currentEnterprise')}`;
  },

  async create(data) {
    const body = {
      type: data.type,
      label: data.label,
      params: {
        min: data.params.min,
        max: data.params.max,
        items: data.params.items,
      },
    };

    return api
      .post(`${this.getBaseUrl()}/`, body)
      .then((response) => response.data);
  },

  async delete(id) {
    return api
      .delete(`${this.getBaseUrl()}/${id}`)
      .then((response) => response.data);
  },

  async update({ data, id }) {
    const body = {
      type: data.type,
      label: data.label,
      params: {
        min: data.params.min,
        max: data.params.max,
        items: data.params.items,
      },
    };
    return api
      .put(`${this.getBaseUrl()}/${id}`, body)
      .then((response) => response.data);
  },

  async getAll(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 15, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/paginate/?${stringParams}`)
      .then((response) => response.data);
  },

  async getById(dataId) {
    return api
      .get(`${this.getBaseUrl()}/${dataId}`)
      .then((response) => response.data);
  },
};
