import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import VueAwesomePaginate from 'vue-awesome-paginate';
import { createApp } from 'vue';
import vSelect from 'vue-select';
import Maska from 'maska';
import Toast, { POSITION } from 'vue-toastification';
import Datepicker from '@vuepic/vue-datepicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueCookies from 'vue3-cookies';

import App from './App.vue';
import router from './router';
import store from './store';
import { permissionPlugin } from './plugins/permissions';

import 'vue-select/dist/vue-select.css';
import { iconsSet as icons } from '@/assets/icons';
import 'vue-awesome-paginate/dist/style.css';
import 'vue-toastification/dist/index.css';
import '@vuepic/vue-datepicker/dist/main.css';

// Bootstrap:
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap';
const options = {
  // You can set your default options here
  position: POSITION.BOTTOM_RIGHT,
};

const app = createApp(App);
app.use(VueCookies);
app.use(CoreuiVue, []);
app.use(store);
app.use(router);
app.use(VueAwesomePaginate);
app.use(Toast, options);
app.use(Maska);
app.use(permissionPlugin);

// app.use(abilitiesPlugin, ability);

app.provide('icons', icons);

app.component('CIcon', CIcon);
app.component('v-select', vSelect);
app.component('Datepicker', Datepicker);

app.mount('#app');
