/* eslint-disable prettier/prettier */
import store from '../store';
import { userRoles } from '@/constants/user-roles';

export function hasPermission(
  action: string,
  resource: string,
  roles?: string[],
) {
  if (!action && !resource && !roles) {
    return true;
  }

  const currentUser = store.getters['loginStore/currentUser'];

  const acceptedPermission =
    action && resource
      ? currentUser.permissions.some(
          (permission: any) =>
            permission.action.toLowerCase() === action.toLowerCase() &&
            permission.resource.toLowerCase() === resource.toLowerCase(),
        )
      : true;

  let roleIsPermitted = false;

  if (roles && roles.length > 0) {
    roleIsPermitted = roles.some((role) =>
      currentUser.user.roles.some(
        (userRole: string) =>
          userRole.toLowerCase() === role.toLocaleLowerCase(),
      ),
    );
  } else {
    roleIsPermitted = currentUser.user.roles.includes(userRoles.ADMIN);
  }

  return acceptedPermission && roleIsPermitted;
}

export function hasPermissions(actions: string[], resource: string) {
  if (!actions && !resource) {
    return true;
  }

  const currentUser = store.getters['loginStore/currentUser'];

  const acceptedPermission = currentUser.permissions.some((permission: any) => {
    if (permission.resource.toLowerCase() === resource.toLowerCase()) {
      return actions
        .map((action) => action.toLowerCase())
        .includes(permission.action.toLowerCase());
    }
    return false;
  });

  return acceptedPermission;
}
