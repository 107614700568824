import queryString from 'query-string';
import { useCookies } from 'vue3-cookies';
import api from './api';

const { cookies } = useCookies();

const baseURL = '/shipments';

export default {
  name: 'shipmentService',

  getBaseUrl() {
    return `${baseURL}/${cookies.get('currentEnterprise')}`;
  },

  async create(data) {
    const body = {
      horsePlate: data.horsePlate,
      initialWeight: data.initialWeight,
      finalWeight: data.finalWeight,
      invoice: data.invoice,
      batch: data.batch,
      billingBase: data.billingBase,
      costumer: data.costumer,
      salesOrder: data.salesOrder,
      seal: data.seal,
      driverName: data.driverName,
      productionNotes: data.productionNotes,
      invoicingNotes: data.invoicingNotes,
      conciergeNotes: data.conciergeNotes,
      quantity: data.quantity,
      vehicle: data.vehicle,
      product: data.product,
      route: data.route,
    };

    return api
      .post(`${this.getBaseUrl()}/`, body)
      .then((response) => response.data);
  },

  async delete(id) {
    return api
      .delete(`${this.getBaseUrl()}/${id}`)
      .then((response) => response.data);
  },

  async update({ data, id }) {
    const body = {
      horsePlate: data.horsePlate,
      initialWeight: data.initialWeight,
      finalWeight: data.finalWeight,
      invoice: data.invoice,
      batch: data.batch,
      billingBase: data.billingBase,
      costumer: data.costumer,
      salesOrder: data.salesOrder,
      seal: data.seal,
      driverName: data.driverName,
      productionNotes: data.productionNotes,
      invoicingNotes: data.invoicingNotes,
      conciergeNotes: data.conciergeNotes,
      quantity: data.quantity,
    };

    return api
      .put(`${this.getBaseUrl()}/${id}`, body)
      .then((response) => response.data);
  },

  async getAll(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 15, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/paginate/?${stringParams}`)
      .then((response) => response.data);
  },

  async getById(dataId) {
    return api
      .get(`${this.getBaseUrl()}/${dataId}`)
      .then((response) => response.data);
  },

  async closeShipment({ data, id }) {
    const body = {
      description: data.description,
    };

    return api
      .post(`${this.getBaseUrl()}/close/${id}`, body)
      .then((response) => response.data);
  },

  async sendQuestionnaireResponses({ data, shipmentId, questionnaireId }) {
    const body = {
      answers: data.answers,
    };

    return api
      .put(`${this.getBaseUrl()}/${shipmentId}/answer/${questionnaireId}`, body)
      .then((response) => response.data);
  },

  async closeQuestionnaire({ data, shipmentId, questionnaireId }) {
    const body = {
      description: data.description,
      approval: data.approval,
    };

    return api
      .put(`${this.getBaseUrl()}/${shipmentId}/close/${questionnaireId}`, body)
      .then((response) => response.data);
  },

  async shipmentsOfDay(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/ofDay/?${stringParams}`)
      .then((response) => response.data);
  },

  async shipmentsOfDayPaginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 15, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/ofDay/paginate/?${stringParams}`)
      .then((response) => response.data);
  },
};
