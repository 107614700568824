import { useToast } from 'vue-toastification';
import companyService from '../../services/companyService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    companies: undefined,
    selectedCompany: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.companies = undefined;
      state.selectedCompany = undefined;
    },
    CLEAR_COMPANIES(state) {
      state.companies = undefined;
    },
    CLEAR_SELECTED_COMPANY(state) {
      state.selectedCompany = undefined;
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies;
    },
    SET_SELECTED_COMPANY(state, company) {
      state.selectedCompany = company;
    },
  },
  actions: {
    async getAllCompanies({ commit }, params) {
      await companyService
        .getAll(params)
        .then((response) => {
          commit('SET_COMPANIES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getCompanyById({ commit }, id) {
      await companyService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_COMPANY', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateCompanies({ commit }, { page, params }) {
      await companyService
        .paginate(page, params)
        .then((response) => {
          commit('SET_COMPANIES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedCompanies({ commit }, params) {
      await companyService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_COMPANIES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createCompany(state, data) {
      await companyService
        .create(data)
        .then(() => {
          toast.success('Transportadora cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateCompany(state, { data, id }) {
      await companyService
        .update({ data, id })
        .then(() => {
          toast.success('Transportadora atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteCompany(state, id) {
      await companyService
        .delete(id)
        .then(() => {
          toast.success('Transportadora deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
