import { userRoles } from '@/constants/user-roles';

export default [
  {
    component: 'CNavItem',
    name: 'Empresas',
    to: '/empresas',
    icon: 'cil-home',
    resource: 'enterprise',
    action: 'access',
    roles: [userRoles.ADMIN],
    needEnterprise: false,
  },
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    resource: 'dashboard',
    action: 'access',
    roles: [
      userRoles.ADMIN,
      userRoles.ENTERPRISE_ADMIN,
      userRoles.MANAGER,
      userRoles.PORTARIA,
      userRoles.KLABIN_MANAGER,
    ],
    needEnterprise: true,
  },
  {
    component: 'CNavItem',
    name: 'Tempo Real',
    to: '/tempo-real',
    icon: 'cil-av-timer',
    resource: 'live_visits',
    action: 'access',
    roles: [
      userRoles.ADMIN,
      userRoles.ENTERPRISE_ADMIN,
      userRoles.MANAGER,
      userRoles.FINANCEIRO,
      userRoles.KLABIN_MANAGER,
      userRoles.VISUALIZATION,
    ],
    needEnterprise: true,
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Tempo Real',
  //   to: '/tempo-real',
  //   icon: 'cil-av-timer',
  //   resource: 'live_shipment',
  //   action: 'access',
  //   roles: [
  //     userRoles.ADMIN,
  //     userRoles.ENTERPRISE_ADMIN,
  //     userRoles.MANAGER,
  //     userRoles.FINANCEIRO,
  //   ],
  //   needEnterprise: true,
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Carregamentos',
  //   to: '/carregamentos',
  //   icon: 'cib-coreui-c',
  //   resource: 'shipment',
  //   action: 'access',
  //   roles: [
  //     userRoles.ADMIN,
  //     userRoles.ENTERPRISE_ADMIN,
  //     userRoles.MANAGER,
  //     userRoles.PORTARIA,
  //     userRoles.FINANCEIRO,
  //   ],
  //   needEnterprise: true,
  // },
  {
    component: 'CNavItem',
    name: 'Transportadora',
    icon: 'cil-truck',
    to: '/transportadora',
    resource: 'shipping_company',
    action: 'access',
    roles: [
      userRoles.ADMIN,
      userRoles.ENTERPRISE_ADMIN,
      userRoles.MANAGER,
      userRoles.FINANCEIRO,
    ],
    needEnterprise: true,
  },
  {
    component: 'CNavItem',
    name: 'Veículos',
    to: '/veiculos',
    icon: 'cil-car-alt',
    resource: 'vehicle',
    action: 'access',
    roles: [
      userRoles.ADMIN,
      userRoles.ENTERPRISE_ADMIN,
      userRoles.MANAGER,
      userRoles.PORTARIA,
    ],
    needEnterprise: true,
  },
  {
    component: 'CNavItem',
    name: 'Relatórios',
    to: '/relatorios',
    icon: 'cil-print',
    resource: 'report',
    action: 'access',
    roles: [
      userRoles.ADMIN,
      userRoles.MANAGER,
      userRoles.ENTERPRISE_ADMIN,
      userRoles.KLABIN_MANAGER,
    ],
    needEnterprise: true,
  },
  {
    component: 'CNavItem',
    name: 'Exportação de Dados',
    to: '/exportacao_de_dados',
    icon: 'cil-share-boxed',
    resource: 'report',
    action: 'access',
    roles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.ENTERPRISE_ADMIN],
    needEnterprise: true,
  },
  {
    component: 'CNavGroup',
    name: 'Configurações',
    to: '/sistema',
    icon: 'cil-settings',
    roles: [
      userRoles.ADMIN,
      userRoles.MANAGER,
      userRoles.ENTERPRISE_ADMIN,
      userRoles.PORTARIA,
    ],
    needEnterprise: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Cargos',
        to: '/sistema/cargos',
        resource: 'role',
        action: 'access',
        roles: [userRoles.ADMIN],
        needEnterprise: false,
      },
      {
        component: 'CNavItem',
        name: 'Usuários',
        to: '/sistema/usuarios',
        resource: 'user',
        action: 'access',
        roles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.ENTERPRISE_ADMIN],
        needEnterprise: false,
      },
      {
        component: 'CNavItem',
        name: 'Tags',
        to: '/sistema/tags',
        resource: 'tag',
        action: 'access',
        roles: [
          userRoles.ADMIN,
          userRoles.MANAGER,
          userRoles.ENTERPRISE_ADMIN,
          userRoles.PORTARIA,
        ],
        needEnterprise: true,
      },
      {
        component: 'CNavItem',
        name: 'Antenas',
        to: '/sistema/antenas',
        resource: 'antenna',
        action: 'access',
        roles: [userRoles.ADMIN, userRoles.ENTERPRISE_ADMIN],
        needEnterprise: true,
      },

      {
        component: 'CNavItem',
        name: 'Produtos',
        to: '/sistema/produtos',
        resource: 'product',
        action: 'access',
        roles: [userRoles.ADMIN, userRoles.ENTERPRISE_ADMIN],
        needEnterprise: true,
      },
      {
        component: 'CNavItem',
        name: 'Estações',
        to: '/sistema/estacoes',
        resource: 'checkpoint',
        action: 'access',
        roles: [userRoles.ADMIN, userRoles.ENTERPRISE_ADMIN],
        needEnterprise: true,
      },
      {
        component: 'CNavItem',
        name: 'Rotas',
        to: '/sistema/rotas',
        resource: 'route',
        action: 'access',
        roles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.ENTERPRISE_ADMIN],
        needEnterprise: true,
      },
      {
        component: 'CNavItem',
        name: 'Questões',
        to: '/sistema/questoes',
        resource: 'question',
        action: 'access',
        roles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.ENTERPRISE_ADMIN],
        needEnterprise: true,
      },
      {
        component: 'CNavItem',
        name: 'Questionários',
        to: '/sistema/questionarios',
        resource: 'questionnaire',
        action: 'access',
        roles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.ENTERPRISE_ADMIN],
        needEnterprise: true,
      },
      {
        component: 'CNavItem',
        name: 'Leitura',
        to: '/sistema/leitura',
        resource: 'reader',
        action: 'access',
        roles: [userRoles.ADMIN],
        needEnterprise: true,
      },
      {
        component: 'CNavItem',
        name: 'Monitoramento',
        to: '/sistema/monitoramento',
        resource: 'monitoring',
        action: 'access',
        roles: [userRoles.ADMIN],
        needEnterprise: true,
      },
    ],
  },
];

// {
//   component: 'CNavTitle',
//   name: 'Theme',
// },
// {
//   component: 'CNavItem',
//   name: 'Colors',
//   to: '/theme/colors',
//   icon: 'cil-drop',
// },
// {
//   component: 'CNavItem',
//   name: 'Typography',
//   to: '/theme/typography',
//   icon: 'cil-pencil',
// },
// {
//   component: 'CNavTitle',
//   name: 'Components',
// },
// {
//   component: 'CNavGroup',
//   name: 'Base',
//   to: '/base',
//   icon: 'cil-puzzle',
//   items: [
//     {
//       component: 'CNavItem',
//       name: 'Accordion',
//       to: '/base/accordion',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Breadcrumbs',
//       to: '/base/breadcrumbs',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Cards',
//       to: '/base/cards',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Carousels',
//       to: '/base/carousels',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Collapses',
//       to: '/base/collapses',
//     },
//     {
//       component: 'CNavItem',
//       name: 'List Groups',
//       to: '/base/list-groups',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Navs & Tabs',
//       to: '/base/navs',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Paginations',
//       to: '/base/paginations',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Placeholders',
//       to: '/base/placeholders',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Popovers',
//       to: '/base/popovers',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Progress',
//       to: '/base/progress',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Spinners',
//       to: '/base/spinners',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Tables',
//       to: '/base/tables',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Tooltips',
//       to: '/base/tooltips',
//     },
//   ],
// },
// {
//   component: 'CNavGroup',
//   name: 'Buttons',
//   to: '/buttons',
//   icon: 'cil-cursor',
//   items: [
//     {
//       component: 'CNavItem',
//       name: 'Buttons',
//       to: '/buttons/standard-buttons',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Button Groups',
//       to: '/buttons/button-groups',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Dropdowns',
//       to: '/buttons/dropdowns',
//     },
//   ],
// },
// {
//   component: 'CNavGroup',
//   name: 'Forms',
//   to: '/forms',
//   icon: 'cil-notes',
//   items: [
//     {
//       component: 'CNavItem',
//       name: 'Form Control',
//       to: '/forms/form-control',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Select',
//       to: '/forms/select',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Checks & Radios',
//       to: '/forms/checks-radios',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Range',
//       to: '/forms/range',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Input Group',
//       to: '/forms/input-group',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Floating Labels',
//       to: '/forms/floating-labels',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Layout',
//       to: '/forms/layout',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Validation',
//       to: '/forms/validation',
//     },
//   ],
// },
// {
//   component: 'CNavItem',
//   name: 'Charts',
//   to: '/charts',
//   icon: 'cil-chart-pie',
// },
// {
//   component: 'CNavGroup',
//   name: 'Icons',
//   to: '/icons',
//   icon: 'cil-star',
//   items: [
//     {
//       component: 'CNavItem',
//       name: 'CoreUI Icons',
//       to: '/icons/coreui-icons',
//       badge: {
//         color: 'info',
//         text: 'NEW',
//       },
//     },
//     {
//       component: 'CNavItem',
//       name: 'Brands',
//       to: '/icons/brands',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Flags',
//       to: '/icons/flags',
//     },
//   ],
// },
// {
//   component: 'CNavGroup',
//   name: 'Notifications',
//   to: '/notifications',
//   icon: 'cil-bell',
//   items: [
//     {
//       component: 'CNavItem',
//       name: 'Alerts',
//       to: '/notifications/alerts',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Badges',
//       to: '/notifications/badges',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Modals',
//       to: '/notifications/modals',
//     },
//   ],
// },
// {
//   component: 'CNavItem',
//   name: 'Widgets',
//   to: '/widgets',
//   icon: 'cil-calculator',
//   badge: {
//     color: 'primary',
//     text: 'NEW',
//     shape: 'pill',
//   },
// },
// {
//   component: 'CNavTitle',
//   name: 'Extras',
// },
// {
//   component: 'CNavGroup',
//   name: 'Pages',
//   to: '/pages',
//   icon: 'cil-star',
//   items: [
//     {
//       component: 'CNavItem',
//       name: 'Login',
//       to: '/pages/login',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Register',
//       to: '/pages/register',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Error 404',
//       to: '/pages/404',
//     },
//     {
//       component: 'CNavItem',
//       name: 'Error 500',
//       to: '/pages/500',
//     },
//   ],
// },
