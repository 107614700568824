import { useToast } from 'vue-toastification';
import roleService from '../../services/roleService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    roles: undefined,
    selectedRole: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.roles = undefined;
      state.selectedRole = undefined;
    },
    CLEAR_ROLES(state) {
      state.roles = undefined;
    },
    CLEAR_SELECTED_ROLE(state) {
      state.selectedRole = undefined;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_SELECTED_ROLE(state, role) {
      state.selectedRole = role;
    },
  },
  actions: {
    async getAllRoles({ commit }, params) {
      await roleService
        .getAll(params)
        .then((response) => {
          commit('SET_ROLES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedRoles({ commit }, params) {
      await roleService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_ROLES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getRoleById({ commit }, id) {
      await roleService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_ROLE', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateRoles({ commit }, { page, params }) {
      await roleService
        .paginate(page, params)
        .then((response) => {
          commit('SET_ROLES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createRole(state, data) {
      await roleService
        .create(data)
        .then(() => {
          toast.success('Cargo cadastrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateRole(state, { data, id }) {
      await roleService
        .update({ data, id })
        .then(() => {
          toast.success('Cargo atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteRole(state, id) {
      await roleService
        .delete(id)
        .then(() => {
          toast.success('Cargo deletado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
