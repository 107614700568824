import Axios from 'axios';
import axiosRetry from 'axios-retry';
import store from '../store';
import Config from '../config';

const baseURL = Config.BASE_API_URL;

const options = {
  baseURL: baseURL || 'http://localhost:8000/api',
};

const api = Axios.create(options);
axiosRetry(api, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isRetryableError,
});

api.interceptors.request.use((config) => {
  const currentUser = store.getters['loginStore/currentUser'];
  if (currentUser.token) {
    config.headers = {
      Authorization: `Bearer ${currentUser.token}`,
    };
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('loginStore/logout');
    }
    return Promise.reject(error);
  },
);

export default api;
