import { useToast } from 'vue-toastification';
import vehicleService from '../../services/vehicleService.js';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    vehicles: undefined,
    selectedVehicle: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.vehicles = undefined;
      state.selectedVehicle = undefined;
    },
    CLEAR_VEHICLES(state) {
      state.vehicles = undefined;
    },
    CLEAR_SELECTED_VEHICLE(state) {
      state.selectedVehicle = undefined;
    },
    SET_VEHICLES(state, vehicles) {
      state.vehicles = vehicles;
    },
    SET_SELECTED_VEHICLE(state, vehicle) {
      state.selectedVehicle = vehicle;
    },
  },
  actions: {
    async getAllVehicles({ commit }, params) {
      await vehicleService
        .getAll(params)
        .then((response) => {
          commit('SET_VEHICLES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedVehicles({ commit }, params) {
      await vehicleService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.vehiclePlate.localeCompare(b.vehiclePlate));
          commit('SET_VEHICLES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getVehicleById({ commit }, id) {
      await vehicleService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_VEHICLE', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateVehicles({ commit }, { page, params }) {
      await vehicleService
        .paginate(page, params)
        .then((response) => {
          commit('SET_VEHICLES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createVehicle(state, data) {
      await vehicleService
        .create(data)
        .then(() => {
          toast.success('Veículo cadastrado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateVehicle(state, { data, id }) {
      await vehicleService
        .update({ data, id })
        .then(() => {
          toast.success('Veículo atualizado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteVehicle(state, id) {
      await vehicleService
        .delete(id)
        .then(() => {
          toast.success('Veículo deletado');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
