<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>

      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img src="@/assets/brand/logo-bionic.png" alt="" height="30" />
      </CHeaderBrand>
      <CHeaderNav>
        <CNavItem v-if="myUser">
          <label for="username" class="pe-2 username desktop pt-2 ms-2">{{
            myUser.name
          }}</label>
        </CNavItem>
        <SelectEnterprise
          v-if="myUser && myUser.roles.includes(existingRoles.ADMIN)"
        />
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import { mapState } from 'vuex';
import { userRoles } from '@/constants/user-roles';

import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt';
import SelectEnterprise from './SelectEnterprise.vue';

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    SelectEnterprise,
  },
  data() {
    return {
      existingRoles: userRoles,
    };
  },
  computed: {
    ...mapState('loginStore', ['myUser']),
  },
};
</script>

<style scoped>
.username {
  font-size: 16px;
  color: #7a7979;
  font-weight: 700;
}
.desktop {
  display: block;
}

@media (max-width: 441px) {
  .desktop {
    display: none;
  }
}
</style>
