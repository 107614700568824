import { useToast } from 'vue-toastification';
import antennaService from '../../services/antennaService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    antennas: undefined,
    selectedAntenna: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.antennas = undefined;
      state.selectedAntenna = undefined;
    },
    CLEAR_ANTENNAS(state) {
      state.antennas = undefined;
    },
    CLEAR_SELECTED_ANTENNA(state) {
      state.selectedAntenna = undefined;
    },
    SET_ANTENNAS(state, antennas) {
      state.antennas = antennas;
    },
    SET_SELECTED_ANTENNA(state, Antenna) {
      state.selectedAntenna = Antenna;
    },
  },
  actions: {
    async getAllAntennas({ commit }, params) {
      await antennaService
        .getAll(params)
        .then((response) => {
          commit('SET_ANTENNAS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedAntennas({ commit }, params) {
      await antennaService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_ANTENNAS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAntennaById({ commit }, id) {
      await antennaService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_ANTENNA', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateAntennas({ commit }, { page, params }) {
      await antennaService
        .paginate(page, params)
        .then((response) => {
          commit('SET_ANTENNAS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createAntenna(state, data) {
      await antennaService
        .create(data)
        .then(() => {
          toast.success('Antena cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateAntenna(state, { data, id }) {
      await antennaService
        .update({ data, id })
        .then(() => {
          toast.success('Antena atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteAntenna(state, id) {
      await antennaService
        .delete(id)
        .then(() => {
          toast.success('Antena deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
