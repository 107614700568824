import { useToast } from 'vue-toastification';
import readerService from '../../services/readerService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    readers: undefined,
    selectedReader: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.readers = undefined;
      state.selectedReader = undefined;
    },
    CLEAR_READERS(state) {
      state.readers = undefined;
    },
    CLEAR_SELECTED_READER(state) {
      state.selectedReader = undefined;
    },
    SET_READERS(state, readers) {
      state.readers = readers;
    },
    SET_SELECTED_READER(state, reader) {
      state.selectedReader = reader;
    },
  },
  actions: {
    async getAllReaders({ commit }, params) {
      await readerService
        .getAll(params)
        .then((response) => {
          commit('SET_READERS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getReaderById({ commit }, id) {
      await readerService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_READER', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateReaders({ commit }, { page, params }) {
      await readerService
        .paginate(page, params)
        .then((response) => {
          commit('SET_READERS', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createReader(state, data) {
      await readerService
        .create(data)
        .then(() => {
          toast.success('Leitura cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateReader(state, { data, id }) {
      await readerService
        .update({ data, id })
        .then(() => {
          toast.success('Leitura atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteReader(state, id) {
      await readerService
        .delete(id)
        .then(() => {
          toast.success('Leitura deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
