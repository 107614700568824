import { useToast } from 'vue-toastification';
import routesService from '../../services/routesService';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    routes: undefined,
    selectedRoute: undefined,
  },
  mutations: {
    CLEAR_STORE(state) {
      state.routes = undefined;
      state.selectedRoute = undefined;
    },
    CLEAR_ROUTES(state) {
      state.routes = undefined;
    },
    CLEAR_SELECTED_ROUTE(state) {
      state.selectedRoute = undefined;
    },
    SET_ROUTES(state, routes) {
      state.routes = routes;
    },
    SET_SELECTED_ROUTE(state, route) {
      state.selectedRoute = route;
    },
  },
  actions: {
    async getAllRoutes({ commit }, params) {
      await routesService
        .getAll(params)
        .then((response) => {
          commit('SET_ROUTES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getAllSortedRoutes({ commit }, params) {
      await routesService
        .getAll(params)
        .then((response) => {
          response.sort((a, b) => a.name.localeCompare(b.name));
          commit('SET_ROUTES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getRouteById({ commit }, id) {
      await routesService
        .getById(id)
        .then((response) => {
          commit('SET_SELECTED_ROUTE', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async getPaginateRoutes({ commit }, { page, params }) {
      await routesService
        .paginate(page, params)
        .then((response) => {
          commit('SET_ROUTES', response);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async createRoute(state, data) {
      await routesService
        .create(data)
        .then(() => {
          toast.success('Rota cadastrada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async updateRoute(state, { data, id }) {
      await routesService
        .update({ data, id })
        .then(() => {
          toast.success('Rota atualizada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
    async deleteRoute(state, id) {
      await routesService
        .delete(id)
        .then(() => {
          toast.success('Rota deletada');
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    },
  },
  modules: {},
  getters: {},
};
