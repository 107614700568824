/* eslint-disable import/no-extraneous-dependencies */
import { useCookies } from 'vue3-cookies';
import queryString from 'query-string';
import api from './api';

const { cookies } = useCookies();

const baseURL = '/tags';

export default {
  name: 'tagsService',

  getBaseUrl() {
    return `${baseURL}/${cookies.get('currentEnterprise')}`;
  },

  async create(data) {
    const body = {
      code: data.code,
      description: data.description,
    };

    return api
      .post(`${this.getBaseUrl()}/`, body)
      .then((response) => response.data);
  },

  async delete(id) {
    return api
      .delete(`${this.getBaseUrl()}/${id}`)
      .then((response) => response.data);
  },

  async update({ data, id }) {
    const body = {
      code: data.code,
      description: data.description,
    };

    return api
      .put(`${this.getBaseUrl()}/${id}`, body)
      .then((response) => response.data);
  },

  async getAll(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 15, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/paginate/?${stringParams}`)
      .then((response) => response.data);
  },

  async getById(dataId) {
    return api
      .get(`${this.getBaseUrl()}/${dataId}`)
      .then((response) => response.data);
  },

  async findAllWithoutVehicle(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${this.getBaseUrl()}/withoutVehicle/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginateWithoutVehicle(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 15, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${this.getBaseUrl()}/withoutVehicle/paginate/?${stringParams}`)
      .then((response) => response.data);
  },
};
