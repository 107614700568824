import router from '../router';
import store from '../store';
import api from './api';

const baseURL = '/auth';

const logout = () => {
  router.push('/login');
  store.commit('loginStore/CLEAR_STORE');
};

export default {
  name: 'auth',

  logout,

  async login(loginData) {
    return api
      .post(`${baseURL}/login`, {
        username: loginData.username,
        password: loginData.password,
      })
      .then((response) => response.data);
  },

  async getMe() {
    return api.get(`${baseURL}/me`).then((response) => response.data);
  },
};
