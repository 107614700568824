import queryString from 'query-string';
import api from './api';

const baseURL = '/unit_of_measures';

export default {
  name: 'measureService',

  async create(data) {
    const body = {
      name: data.name,
    };

    return api.post(`${baseURL}/`, body).then((response) => response.data);
  },

  async delete(id) {
    return api.delete(`${baseURL}/${id}`).then((response) => response.data);
  },

  async update({ data, id }) {
    const body = {
      name: data.name,
    };

    return api.put(`${baseURL}/${id}`, body).then((response) => response.data);
  },

  async getAll(params = {}) {
    const stringParams = queryString.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    });
    return api
      .get(`${baseURL}/?${stringParams}`)
      .then((response) => response.data);
  },

  async paginate(page = 1, params = {}) {
    const stringParams = queryString.stringify(
      { page, limit: 15, ...params },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );
    return api
      .get(`${baseURL}/paginate/?${stringParams}`)
      .then((response) => response.data);
  },

  async getById(dataId) {
    return api.get(`${baseURL}/${dataId}`).then((response) => response.data);
  },
};
